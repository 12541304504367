import TestModal from "./components/testModal.vue";
import CreateTemplateModal from "../Common/CreateTemplateModal/index.vue";
import ApiClient from "@/services/ApiClient";
import "floating-vue/dist/style.css";
import { Mentionable } from "vue-mention";
import moment from "moment";

export default {
  name: "CreateSMSTemplate",
  components: {
    CreateTemplateModal,
    Mentionable,
    TestModal,
  },
  data() {
    return {
      audiencesList: [],
      choosenAudience: null,
      confirmationFastJourney: false,
      choosenConnection: null,
      openTestModal: false,
      descSms: "",
      showInformations: true,
      showModal: false,
      loadingTempalte: false,
      typeTemplate: "",
      stepperValue: 1,
      dbConfigList: [],
      dateOptions: [
        {
          format: "YYYY-MM-DD",
          description: "ISO 8601 date format",
          example: "2023-05-02",
        },
        {
          format: "DD/MM/YYYY",
          description: "UK/Canada date format",
          example: "02/05/2023",
        },
        {
          format: "MM/DD/YYYY",
          description: "USA date format",
          example: "05/02/2023",
        },
        {
          format: "DD/MM/YY",
          description: "Short USA date format",
          example: "05/02/23",
        },
        {
          format: "Do MMMM YYYY",
          description: "Long format",
          example: "2nd May 2023",
        },
      ],
      dateTimeOptions: [
        {
          format: "YYYY-MM-DD",
          description: "ISO 8601 date format",
          example: "2023-05-02",
        },
        {
          format: "DD/MM/YYYY",
          description: "UK/Canada date format",
          example: "02/05/2023",
        },
        {
          format: "MM/DD/YYYY",
          description: "USA date format",
          example: "05/02/2023",
        },
        {
          format: "DD/MM/YY",
          description: "Short USA date format",
          example: "05/02/23",
        },
        {
          format: "Do MMMM YYYY",
          description: "Long format",
          example: "2nd May 2023",
        },
        {
          format: "YYYY-MM-DDTHH:mm:ssZ",
          description: "ISO 8601 format",
          example: "2023-05-02T14:30:00Z",
        },
        {
          format: "DD/MM/YYYY HH:mm:ss",
          description: "UK/Canada format",
          example: "02/05/2023 14:30:00",
        },
        {
          format: "DD/MM/YYYY HH:mm",
          description: "UK/Canada format",
          example: "02/05/2023 14:30",
        },
        {
          format: "MM/DD/YYYY HH:mm:ss",
          description: "USA format",
          example: "05/02/2023 14:30:00",
        },
        {
          format: "MM/DD/YYYY HH:mm",
          description: "USA format",
          example: "05/02/2023 14:30",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss",
          description: "Database format",
          example: "2023-05-02 14:30:00",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss.SSS",
          description: "UK/Canada format",
          example: "2023-03-08 00:00:00.000000",
        },
      ],
      steppers: [
        { id: 1, label: "Conexão", icon: "$check_rounded" },
        { id: 2, label: "Mapeamento de Dados", icon: "$check_rounded" },
        { id: 3, label: "Informações auxiliares", icon: "$check_rounded" },
        { id: 4, label: "Atualização", icon: "$check_rounded" },
      ],
      step: 1,
      items: [],
      variables: [],
      utmNames: [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ],
      mapping: [
        "ID Único",
        "Nome do usuário (opcional)",
        "Telefone",
        "ID SDK",
        "E-mail",
      ],
      mapping2: ["ID da compra", "Valor da compra", "Data da compra"],
      mapping3: ["Valor", "Produto"],
      text: "center",
      zStyle: {
        padding: "1.5rem .5rem",
        display: "flex",
        flexDirection: "column",
      },
      http: new ApiClient(),
      showTagsModal: false,
      firstData: {},
      objToSendTest: null,
      templateToSave: {
        message: "",
        templateType: "sms",
        templateName: "",
        description: "",
      },
      databaseFields: {},
      defaultValues: {},
      defaultFormats: {},
      openVariablesModal: false,
      openUtmModal: false,
      charactersCount: 0,
      totalSMSs: 1,
      totalMessageLength: 160,
      tags: [
        { text: "ID", value: "{ID}" },
        { text: "Nome", value: "{NAME}" },
        { text: "Telefone", value: "{PHONE}" },
        { text: "E-mail", value: "{EMAIL}" },
        { text: "SKU", value: "{SKUID}" },
      ],
    };
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.showInformations = false;
    }
  },
  computed: {
    isEditing() {
      return this.$route.query.id != null;
    },
  },
  async created() {
    await this.getPrimaryDataConfigList();
    if (this.$route.query.id) {
      this.showInformations = true;
      await this.http
        .get(`templatesConfiguration/findOne/${this.$route.query.id}`)
        .then(async (resp) => {
          this.templateToSave = resp;
          this.canShow = true;
          this.showInformations = true;
          if (this.templateToSave.databaseConfigId) {
            this.choosenConnection = this.dbConfigList.find(
              (x) => x.id == this.templateToSave.databaseConfigId
            );

            this.chooseCollection();

            await this.getAudiences();
          }

          const response = await this.http.get("messageCredentials");
          if (!this.templateToSave.utm_source)
            this.templateToSave.utm_source = response.utm_source;
          if (!this.templateToSave.utm_medium)
            this.templateToSave.utm_medium = response.utm_medium;
          if (!this.templateToSave.utm_campaign)
            this.templateToSave.utm_campaign = response.utm_campaign;
          if (!this.templateToSave.utm_id)
            this.templateToSave.utm_id = response.utm_id;
          if (!this.templateToSave.utm_term)
            this.templateToSave.utm_term = response.utm_term;
          if (!this.templateToSave.utm_content)
            this.templateToSave.utm_content = response.utm_content;

          if (resp.defaultValues)
            this.defaultValues = JSON.parse(resp.defaultValues);

          if (resp.defaultFormats)
            this.defaultFormats = JSON.parse(resp.defaultFormats);
        })
        .catch((err) => {
          throw err;
        });
    } else {
      this.canShow = true;

      const response = await this.http.get("messageCredentials");
      this.templateToSave.utm_source = response.utm_source;
      this.templateToSave.utm_medium = response.utm_medium;
      this.templateToSave.utm_campaign = response.utm_campaign;
      this.templateToSave.utm_id = response.utm_id;
      this.templateToSave.utm_term = response.utm_term;
      this.templateToSave.utm_content = response.utm_content;
    }
  },
  watch: {
    "templateToSave.message": {
      handler(nv) {
        this.charactersCount = this.countSMSCharacters(nv);
        this.totalMessageLength = this.checkCharacterOutOfGSM(nv);
        this.totalSMSs = Math.ceil(
          this.charactersCount / this.totalMessageLength
        );
      },
    },
  },
  methods: {
    async getAudiences() {
      try {
        const res = await this.http.get(
          `audience/list?databaseConfigId=${this.templateToSave.databaseConfigId}`
        );
        this.audiencesList = res;
      } catch (error) {
        console.error(error);
      }
    },
    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      return `${PART_1}${PART_2}`;
    },
    createSimpleJourney() {
      if (this.$refs.confirmFastForm.validate()) {
        let obj = {};

        obj.template = { ...this.templateToSave };
        obj.audience = { ...this.choosenAudience };

        this.$store.dispatch("app/CREATE_BASIC_JOURNEY", obj);

        this.$router.push("/journey");
      }
    },
    async chooseCollection() {
      const keys = Object.keys(JSON.parse(this.choosenConnection.fields));
      console.log(keys);

      await this.http
        .get(`dbConfiguration/getFirstData/${this.choosenConnection.id}`)
        .then((resp) => {
          console.log(resp);
          this.firstData = resp;
        })
        .catch((err) => {
          throw err;
        });

      await this.http
        .get(
          `templatesConfiguration/getVariableFields/${this.choosenConnection.id}`
        )
        .then((resp) => {
          this.databaseFields = resp;
          let keys = Object.keys(resp);
          this.variables = keys.map((key) => {
            return { value: key };
          });
        })
        .catch((err) => {
          throw err;
        });
    },
    openTestModalFunc() {
      this.objToSendTest = { ...this.templateToSave };
      this.objToSendTest.message = this.transformString(
        this.templateToSave.message
      );
      this.openTestModal = true;
    },
    openVariablesModalFunc() {
      this.openVariablesModal = true;
    },
    openUtmsModalFunc() {
      this.openUtmModal = true;
    },
    transformString(text) {
      if (this.firstData && this.firstData.length > 0) {
        for (let j = 0; j < this.firstData.length; j++) {
          let keys = this.variables.map((x) => x.value);

          keys = keys.sort((a, b) => b.length - a.length);

          for (let i = 0; i < keys.length; i++) {
            let fieldConfig = this.databaseFields[keys[i]];
            let fieldValue = "";

            if (!this.firstData[j][keys[i]] || !fieldConfig) continue;

            if (fieldConfig.type === "decimal") {
              fieldValue = parseFloat(this.firstData[j][keys[i]])
                .toFixed(2)
                .replace(".", ",");
            } else if (fieldConfig.type === "date") {
              fieldValue = moment
                .utc(this.firstData[j][keys[i]])
                .format("DD/MM/yyyy");
            } else if (fieldConfig.type === "datetime") {
              fieldValue = moment
                .utc(this.firstData[j][keys[i]])
                .format("DD/MM/yyyy hh:mm");
            } else {
              fieldValue = this.firstData[j][keys[i]];
            }

            //eslint-disable-next-line
            let patternString = "\\$" + keys[i] + "(?![a-zA-Z_])";
            let pattern = new RegExp(patternString, "gi");
            if (fieldValue) text = text.replace(pattern, fieldValue);
          }
        }
      }
      return text;
    },
    onApply(item, key, replacedWith) {
      console.log(item, `has been replaced with ${replacedWith}`);
    },
    async getPrimaryDataConfigList() {
      try {
        const req = await this.http.get(
          "dbConfiguration/list?status=updated&sort=created&order=DESC"
        );
        this.dbConfigList = req;
      } catch (error) {
        this.$toast.error("Erro ao buscar lista de configurações");
      }
    },
    countSMSCharacters(message) {
      const GSM_CHARS =
        "@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";

      let messageLength = 0;
      for (let i = 0; i < message.length; i++) {
        const charCode = message.charCodeAt(i);
        if (charCode <= 127 || GSM_CHARS.indexOf(message[i]) !== -1) {
          messageLength++;
        } else {
          messageLength += 2;
        }
      }

      return messageLength;
    },

    checkCharacterOutOfGSM(message) {
      //eslint-disable-next-line
      const gsmRegex =
        // eslint-disable-next-line no-useless-escape
        /^[@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&\'\(\)\*\+,\-\.\/0-9:;<=>\?¡A-ZÄÖÑÜ§¿a-zäöñüà\n\f\r^{}\\\[\]~\|€]+$/;

      if (!gsmRegex.test(message)) {
        return 70;
      } else {
        return 160;
      }
    },

    countChars() {
      this.charactersCount = `${this.templateToSave.message.length}/160`;
    },

    openModalTags() {
      this.showTagsModal = true;
    },
    async saveTemplate() {
      const $context = this;
      if (this.$refs.form.validate()) {
        if (!this.$route.query.id) {
          this.loadingTempalte = true;

          this.templateToSave.databaseConfigId = this.choosenConnection?.id;
          this.templateToSave.defaultValues = JSON.stringify(
            this.defaultValues
          );

          try {
            const req = await $context.http.post(
              "templatesConfiguration/create",
              $context.templateToSave
            );
            if (req) {
              this.$toast.success("Template salvo com sucesso!");
              this.$router.push("/templates");
            }

            this.loadingTempalte = false;
          } catch (error) {
            this.$toast.error("Erro ao salvar template!");
            this.loadingTempalte = false;
          }
        } else {
          this.templateToSave.databaseConfigId = this.choosenConnection?.id;
          this.templateToSave.defaultValues = JSON.stringify(
            this.defaultValues
          );

          try {
            this.loadingTempalte = true;
            $context.templateToSave.newEmailTemplate = true;
            const req = await $context.http.put(
              `templatesConfiguration/${this.$route.query.id}`,
              $context.templateToSave
            );

            if (req) this.$toast.success("Template atualizado com sucesso");
            this.$router.push("/templates");
            this.loadingTempalte = false;
          } catch (error) {
            this.$toast.error("Erro ao atualizar template!");
            this.loadingTempalte = false;
          }
        }
      } else {
        this.$toast.error("Preencha os campos obrigatórios!");
      }
    },
    showModalTemplate(typeTemplate) {
      this.showModal = true;
      this.typeTemplate = typeTemplate;
    },
  },
};
